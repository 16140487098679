import {
  ShareStatisticsResponseModel,
  TextShareStatisticsResponseModel,
} from '@capturi/api-insights'
import {
  PhoneSegmentState,
  Segment,
  SegmentState,
  TextSegmentState,
} from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { UserSegments } from '../../../../analytics/shared/types'

type Datum = {
  targets: number
  targetsShare: number
  targetsSharePercent: number
  stateIndex: number
}

export type TargetUserSegmentsData = UserSegments<Datum>

export type TargetStatistics = {
  targets: number
  targetsShare: number
  targetsSharePercent: number
  users: {
    userUid: string
    targets: number
    targetsShare: number
    targetsSharePercent: number
  }[]
}

function convertConversationStatisticsToTargetStatistics(
  conversationStatistics: ShareStatisticsResponseModel | null,
): TargetStatistics | null {
  return conversationStatistics
    ? {
        targets: conversationStatistics.conversations,
        targetsShare: conversationStatistics.conversationsShare,
        targetsSharePercent: conversationStatistics.conversationsSharePercent,
        users: conversationStatistics.users.map((u) => ({
          userUid: u.userUid,
          targets: u.conversations,
          targetsShare: u.conversationsShare,
          targetsSharePercent: u.conversationsSharePercent,
        })),
      }
    : null
}

function convertTextStatisticsToTargetStatistics(
  textStatistics: TextShareStatisticsResponseModel | null,
): TargetStatistics | null {
  return textStatistics
    ? {
        targets: textStatistics.cases,
        targetsShare: textStatistics.casesShare,
        targetsSharePercent: textStatistics.casesSharePercent,
        users: textStatistics.users.map((u) => ({
          userUid: u.userUid,
          targets: u.cases,
          targetsShare: u.casesShare,
          targetsSharePercent: u.casesSharePercent,
        })),
      }
    : null
}

export function convertHitsToTextUserItemSegments(
  phoneSegmentStates: PhoneSegmentState[],
  phoneSegments: Segment<ShareStatisticsResponseModel>[],
  textSegmentStates: TextSegmentState[],
  textSegments: Segment<TextShareStatisticsResponseModel>[],
  getIndexForState: (state: SegmentState) => number,
): Segment<TargetStatistics>[] {
  const segments = [
    ...phoneSegments.map<Segment<TargetStatistics> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(phoneSegmentStates[index]),
        data: convertConversationStatisticsToTargetStatistics(x.data),
      }),
    ),
    ...textSegments.map<Segment<TargetStatistics> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(textSegmentStates[index]),
        data: convertTextStatisticsToTargetStatistics(x.data),
      }),
    ),
  ]
  // ensure segments are sorted by state.index, important for Datum
  const sortedSegments = segments.sort((a, b) => a.index - b.index)
  return sortedSegments.map(({ index: _, ...segment }) => segment)
}

export function useUserBreakdownStats(
  segments: Segment<TargetStatistics>[],
): TargetUserSegmentsData[] {
  const { getUserByUid } = useUsers()

  return useMemo(() => {
    const allUserUids = new Set(
      segments.flatMap((s) => (s.data?.users ?? []).map((x) => x.userUid)),
    )

    const segmentUserData = segments.map<{ [key: string]: Datum }>(
      (s, index) => {
        return (s.data?.users ?? []).reduce<{
          [key: string]: Datum
        }>((acc, x) => {
          acc[x.userUid] = {
            targets: x.targets,
            targetsShare: x.targetsShare,
            targetsSharePercent: x.targetsSharePercent / 100,
            stateIndex: index,
          }
          return acc
        }, {})
      },
    )

    const segmentData = [...allUserUids].reduce<{
      [key: string]: TargetUserSegmentsData
    }>((acc, userUid) => {
      acc[userUid] = {
        segments: segments.reduce<Segment<Datum>[]>((acc, s, i) => {
          const map = segmentUserData[i][userUid]
          if (map != null) {
            acc.push({
              color: s.color,
              label: s.label,
              data: map ?? {
                targets: 0,
                targetsShare: 0,
                targetsSharePercent: 0,
              },
            })
          }

          return acc
        }, []),
        user: getUserByUid(userUid),
      }
      return acc
    }, {})

    return orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.targetsSharePercent, (x) => x.user.name],
      ['desc', 'asc'],
    )
  }, [segments, getUserByUid])
}
