import { Box, Grid } from '@chakra-ui/react'
import React from 'react'

import { Filters } from './Filters'
import { Results } from './Results'

export const Workspace: React.FC = () => {
  return (
    <Grid
      templateColumns={{
        xl: '1fr 1px 1fr',
        '2xl': '1fr 1px 2fr',
      }}
      gap={8}
      w="100%"
      templateRows="auto"
      alignContent={{ base: 'flex-start', xl: 'stretch' }}
    >
      <Filters />
      <Box
        borderRight="1px"
        borderColor="gray.300"
        display={{ base: 'none', xl: 'block' }}
      />
      <Results />
    </Grid>
  )
}
