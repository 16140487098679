import { useCurrentUser } from '@capturi/core'
import {
  MultiSegmentContainer,
  SegmentsSubFilterContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { Caption, Underlined } from '@capturi/ui-components'
import { Box, FormLabel, Stack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

export const Filters: React.FC = () => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  return (
    <Stack spacing={4}>
      <Box>
        <FormLabel mb={0}>
          1. <Trans>Data basis</Trans>
        </FormLabel>
        <Caption color="textMuted">
          <Trans>
            Select the <Underlined>total amount</Underlined> of conversations
            you want to analyze on. <br />
            If nothing is selected, all conversations are used in the analysis.
          </Trans>
        </Caption>
        <MultiSegmentContainer
          filterDefinitions={filterDefinitions}
          showExportButton={false}
          showNotificationButton={false}
          showSaveButton={false}
          disabledChannels={[]}
        />
      </Box>
      <Box>
        <FormLabel mb={0}>
          2. <Trans>Filtering</Trans>
        </FormLabel>
        <Caption color="textMuted">
          <Trans>
            Select the <Underlined>filtered subset</Underlined> of conversations
            you want to see hit rates for.
          </Trans>
        </Caption>
        <SegmentsSubFilterContainer
          filterDefinitions={filterDefinitions}
          disabledChannels={[]}
        />
      </Box>
    </Stack>
  )
}
