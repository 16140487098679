import {
  ShareStatisticsResponseModel,
  TextShareStatisticsResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import {
  useFetchPhoneSegments,
  useFirstPhoneSegmentState,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { ContentPlaceholder } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import NoAccessibleTrackers from 'pages/analytics/shared/components/NoAccessibleTrackers'
import TeamFilterRequiredMessage from 'pages/analytics/shared/components/TeamFilterRequiredMessage'
import { formatValue } from 'pages/analytics/shared/utils'
import React from 'react'
import { MdPeople } from 'react-icons/md'

import { useFetchTextSegments } from '../../hooks/useFetchTextSegments'
import { TeamBreakdown } from '../TeamBreakdown'
import { TimeSeries } from '../TimeSeries'
import {
  UserBreakdown,
  convertHitsToTextUserItemSegments,
} from '../UserBreakdown'

export const Results: React.FC = () => {
  const { phoneSegmentStates, textSegmentStates, getIndexForState } =
    useSegmentStatesContext()

  const {
    segments: phoneSegments,
    isLoading: isLoadingPhone,
    error: segmentError,
  } = useFetchPhoneSegments<ShareStatisticsResponseModel>(
    insightsAPI.getShareStatisticsByUser,
    (state, _, periodDef, mapperFn) => {
      return {
        baseFilter: mapperFn(state.values, periodDef),
        shareFilter: mapperFn(state.subFilterState?.values, periodDef),
      }
    },
  )
  const currentUser = useCurrentUser()

  const { isLoading: isLoadingText, segments: textSegments } =
    useFetchTextSegments<TextShareStatisticsResponseModel>({
      url: 'insights/share/text/users',
    })

  const res = convertHitsToTextUserItemSegments(
    phoneSegmentStates,
    phoneSegments,
    textSegmentStates,
    textSegments,
    getIndexForState,
  )

  // Check for team lead filter presence
  const { getFilterRequestModel } = useFirstPhoneSegmentState()
  const filter = getFilterRequestModel()
  if (
    currentUser.isTeamLead &&
    !filter.teamUids &&
    segmentError?.statusCode === 403
  )
    return <TeamFilterRequiredMessage />

  if (segmentError && segmentError.statusCode === 403)
    return <NoAccessibleTrackers />

  if (segmentError) return <DefaultFallbackComponent error={segmentError} />

  const isEmpty = !(
    isLoadingPhone ||
    phoneSegments.some(
      (segment) => segment.data && segment.data.conversations !== 0,
    ) ||
    isLoadingText ||
    textSegments.some((segment) => segment.data && segment.data.cases !== 0)
  )

  if (isEmpty) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>No results</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            Try extending the period in the top right corner or make your
            filtration wider
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Box overflow="hidden">
      <Stack isInline spacing={{ base: 2, lg: 4 }}>
        {res.map((s) => {
          const hitrate = (s.data?.targetsSharePercent ?? 0) / 100
          const hits = s.data?.targetsShare ?? 0
          const total = s.data?.targets ?? 0
          const color = `${s.color}.500`
          return (
            <Box
              key={s.label}
              borderBottom="2px"
              borderColor={color}
              px={{ base: 2, md: 4 }}
              py={{ base: 1, md: 2 }}
            >
              <Flex align="flex-end" mb={1}>
                <Text
                  fontSize={{ base: 'xl', md: '2xl' }}
                  fontWeight="medium"
                  letterSpacing="0.05em"
                  lineHeight={1}
                >
                  {formatValue(hitrate, {
                    style: 'percent',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  })}
                </Text>
                <Icon as={MdPeople} ml={2} boxSize={4} color={color} />
              </Flex>
              <Text fontSize={{ base: 'xs', md: 'sm' }} color="textMuted">
                <Trans>
                  {hits} out of {total} conversations
                </Trans>
              </Text>
            </Box>
          )
        })}
      </Stack>
      <Box overflow="auto" mt={4}>
        <Stack spacing={8}>
          <Box>
            <Text mb={4} fontSize="md" fontWeight="medium">
              <Trans>Development over time</Trans>
            </Text>
            <TimeSeries segmentAverages={res} />
          </Box>
          <Box>
            <Tabs isLazy mt={4}>
              <TabList>
                <Tab key="employees">{t`Employees`}</Tab>
                <Tab key="teams">{t`Teams`}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel key="employees" px={0}>
                  <UserBreakdown
                    isLoading={isLoadingPhone || isLoadingText}
                    segments={res}
                  />
                </TabPanel>
                <TabPanel key="teams" px={0}>
                  <TeamBreakdown />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
