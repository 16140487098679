import {
  Segment,
  toFilterSearchParams,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { ConversationsDrawer } from 'components/ConversationsDrawer'
import { ColumnDefinition, DataGrid } from 'components/DataGrid'
import NoDataText from 'pages/analytics/shared/components/NoDataText'
import UserBreakdownSkeleton from 'pages/analytics/shared/components/UserBreakdownSkeleton'
import { UserSegments } from 'pages/analytics/shared/types'
import { memo, useCallback, useMemo } from 'react'

import { Event, logEvent } from '../../../../analytics/shared/events'
import { formatValue, pctFormat } from '../../../../analytics/shared/utils'
import {
  TargetStatistics,
  TargetUserSegmentsData,
  useUserBreakdownStats,
} from './useUserBreakdownStats'

import { createPeriodParams } from '@capturi/filters'
import { useCasesDrawer } from '../../../../../components/CasesDrawer'

type Props = {
  isLoading?: boolean
  segments: Segment<TargetStatistics>[]
}

export const UserBreakdown = memo<Props>(({ segments, isLoading }) => {
  const stats = useUserBreakdownStats(segments)

  const { periodDef } = useFilterPeriodContext()
  const { states } = useSegmentStatesContext()
  const [openConversationsDrawer] = useModal(ConversationsDrawer)
  const openCasesDrawer = useCasesDrawer()

  const handleGoToPhoneTrackerUserConversations = useCallback(
    (stateIndex: number, row: UserSegments): void => {
      const state = states[stateIndex]
      if (state.channel !== 'phone') {
        return
      }

      logEvent(Event.DataGrid_ViewUserConversations, { dataTabName: 'hitrate' })
      openConversationsDrawer({
        url: `insights/share/${row.user.uid}/conversations?api-version=3.3`,
        getFilterRequestModel: () => {
          const { values, subFilterState } = state
          return {
            baseFilter: toFilterSearchParams(
              {
                ...values,
                userUids: [row.user.uid],
              },
              periodDef,
            ),
            shareFilter: toFilterSearchParams(
              subFilterState?.values,
              periodDef,
            ),
          }
        },
      })
    },
    [states, openConversationsDrawer, periodDef],
  )

  const handleGoToEmailTrackerUserConversations = useCallback(
    (stateIndex: number, row: UserSegments): void => {
      const state = states[stateIndex]
      if (state.channel !== 'email') {
        return
      }

      logEvent(Event.DataGrid_ViewUserConversations, { dataTabName: 'hitrate' })

      openCasesDrawer({
        url: `insights/share/text/user/${row.user.uid}/cases?api-version=3.3`,
        getFilterRequestModel: () => {
          const { values, subFilterState } = state
          const periodParams = createPeriodParams(periodDef.create(new Date()))
          return {
            baseFilter: {
              ...values,
              ...periodParams,
            },
            shareFilter: {
              ...subFilterState?.values,
              ...periodParams,
            },
          }
        },
      })
    },
    [openCasesDrawer, states, periodDef],
  )

  const columns = useMemo(() => {
    const columns: Array<ColumnDefinition<TargetUserSegmentsData>> = [
      {
        type: 'user',
        getValue: (d) => d.user.uid,
      },
      {
        type: 'value',
        getSegmentValue: (s) => s.data?.targetsSharePercent,
        formatValue: (value) => formatValue(value, pctFormat),
        alignRight: true,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.targetsSharePercent,
        referenceLines: segments.reduce<
          { value: number; color: string; label: string }[]
        >((memo, s) => {
          if (s.data != null) {
            const value = s.data.targetsSharePercent / 100
            memo.push({
              label: i18n.number(value, pctFormat),
              color: s.color,
              value,
            })
          }
          return memo
        }, []),
      },
      {
        type: 'dataBasis',
        getSegmentValue: (s) => {
          const { targetsShare = 0, targets = 0 } = s.data ?? {}
          return {
            value: targetsShare,
            total: targets,
          }
        },
      },
      {
        type: 'button',
        render: (row, rowSegmentIndex) => {
          const data = row.segments[rowSegmentIndex].data
          if (!data) return <Box />
          const stateIndex = data.stateIndex
          const isEmailChannel = states[stateIndex].channel === 'email'
          return (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() =>
                isEmailChannel
                  ? handleGoToEmailTrackerUserConversations(stateIndex, row)
                  : handleGoToPhoneTrackerUserConversations(stateIndex, row)
              }
            />
          )
        },
      },
    ]
    return columns
  }, [
    segments,
    handleGoToPhoneTrackerUserConversations,
    handleGoToEmailTrackerUserConversations,
    states,
  ])

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid data={stats} columns={columns}>
        <NoDataText show={!isLoading && stats.length === 0} mt={8} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
})
