import { ListCase, getNextPageParam, selectCases } from '@capturi/api-cases'
import request from '@capturi/request'
import { useUsers } from '@capturi/stores'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useInfiniteQuery } from '@tanstack/react-query'
import { FC, memo, useCallback } from 'react'
import { MdLaunch } from 'react-icons/md'

import { useTimeout } from 'react-use'
import { useCaseDetailsDrawer } from '../../components/CaseDetailsDrawer'

type DrawerOptions = {
  url: string
  getFilterRequestModel: () => Record<string, unknown>
  forceIsLoading?: boolean
}

export type CasesDrawerProps = DrawerOptions & Omit<DrawerProps, 'children'>

const dateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

export const CasesDrawer: FC<CasesDrawerProps> = ({
  url,
  getFilterRequestModel,
  isOpen,
  onClose,
}) => {
  const [isReady] = useTimeout(500)
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          alignItems="center"
          pr={10}
        >
          <Trans>Conversations</Trans>
        </DrawerHeader>
        <DrawerBody overflow="hidden" p={0}>
          <DrawerBodyContent
            url={url}
            getFilterRequestModel={getFilterRequestModel}
            forceIsLoading={!isReady()}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const DrawerBodyContent: FC<DrawerOptions> = ({
  url,
  getFilterRequestModel,
  forceIsLoading = false,
}) => {
  const { getUserByUid } = useUsers()
  const openCaseDetailsDrawer = useCaseDetailsDrawer()

  const filter = getFilterRequestModel()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['cases', url, filter],
      queryFn: async ({ pageParam }) =>
        await request.post(url, {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: 50,
                sortDirection: 1,
              },
        }),
      getNextPageParam,
      initialPageParam: null,
      select: selectCases,
    })

  const handleLoadMore = useCallback((): void => {
    fetchNextPage()
  }, [fetchNextPage])

  const handleOpenCaseDetailsDrawer = useCallback(
    (caseUid: string) => {
      openCaseDetailsDrawer({ caseUid })
    },
    [openCaseDetailsDrawer],
  )

  return (
    <Box h="full" overflowX="hidden">
      {isLoading || forceIsLoading ? (
        <VStack gap={4} mx={4} mt={4}>
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
        </VStack>
      ) : (
        <>
          {(data?.pages ?? []).map((page) =>
            page.map((listItem) => (
              <CaseListItem
                key={listItem.uid}
                listItem={listItem}
                getUserByUid={getUserByUid}
                handleOpenCaseDetailsDrawer={handleOpenCaseDetailsDrawer}
              />
            )),
          )}
          <Flex my={4} justify="center" w="100%">
            <Button
              onClick={handleLoadMore}
              isDisabled={!hasNextPage || isFetchingNextPage}
              isLoading={isFetchingNextPage}
              borderRadius="md"
              size="sm"
            >
              {!hasNextPage ? (
                <Trans>No more conversations</Trans>
              ) : (
                <Trans>Load more</Trans>
              )}
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

type CaseListItemProps = {
  listItem: ListCase
  getUserByUid: (uid?: string) => { name: string; email: string }
  handleOpenCaseDetailsDrawer: (uid: string) => void
}

const CaseListItem: FC<CaseListItemProps> = memo(
  ({
    listItem: { uid, created, users, subject, messageFlow },
    getUserByUid,
    handleOpenCaseDetailsDrawer,
  }) => (
    <Card
      border="1px"
      borderRadius="base"
      borderColor="gray.300"
      shadow="md"
      cursor="pointer"
      overflow="hidden"
      my={4}
      mx={4}
    >
      <CardHeader
        bg="gray.200"
        px={4}
        py={2}
        onClick={() => handleOpenCaseDetailsDrawer(uid)}
        data-group
        _hover={{ bg: 'gray.300' }}
      >
        <VStack align="start" gap={0}>
          <HStack justifyContent="space-between" w="100%">
            <Text
              fontSize="md"
              fontWeight="medium"
              _groupHover={{ textDecoration: 'underline' }}
            >
              {subject}
            </Text>
            <Text
              _groupHover={{ visibility: 'hidden' }}
              fontSize="sm"
              textAlign="right"
              textColor="gray.600"
            >
              {getUserByUid(users.at(0)).name}
            </Text>
            <IconButton
              aria-label={t`Go to conversation`}
              display="none"
              _groupHover={{ display: 'block' }}
              _hover={{ background: 'none' }}
              icon={<MdLaunch />}
              onClick={() => handleOpenCaseDetailsDrawer(uid)}
              size="2xs"
              textColor="gray.600"
              variant="ghost"
            />
          </HStack>
          <HStack
            justifyContent="space-between"
            fontSize="sm"
            textColor="gray.600"
            w="100%"
          >
            <Text noOfLines={1} flexShrink={0}>
              {`${messageFlow.firstType} | ${i18n.date(
                new Date(created),
                dateFormat,
              )}`}
            </Text>
            <Text noOfLines={1}>{getUserByUid(users.at(0)).email}</Text>
          </HStack>
        </VStack>
      </CardHeader>
    </Card>
  ),
)
