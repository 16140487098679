import {
  Segment,
  createPeriodParams,
  segmentConfigurations,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import request, { ResponseError } from '@capturi/request'
import { UseQueryOptions, useQueries } from '@tanstack/react-query'
import { StringifiableRecord } from 'query-string'
import { useMemo } from 'react'

export function useFetchTextSegments<T>({
  url,
  query,
}: {
  url: string
  query?: StringifiableRecord
}): {
  isLoading: boolean
  segments: Segment<T>[]
} {
  const { textSegmentStates, getIndexForState } = useSegmentStatesContext()
  const { periodDef } = useFilterPeriodContext()

  const responses = useQueries({
    queries: textSegmentStates.map<UseQueryOptions<T, ResponseError>>(
      (textSegmentState) => ({
        queryKey: [
          url,
          query,
          textSegmentState.values,
          textSegmentState.subFilterState?.values,
          periodDef,
        ],
        queryFn: (): Promise<T> =>
          request.post(url, {
            query: {
              'api-version': '3.3',
              ...query,
            },
            json: {
              baseFilter: {
                ...textSegmentState.values,
                ...createPeriodParams(periodDef.create(new Date())),
              },
              shareFilter: {
                ...textSegmentState.subFilterState?.values,
                ...createPeriodParams(periodDef.create(new Date())),
              },
            },
          }),
        suspense: false,
      }),
    ),
  })
  const isLoading = responses.some((r) => r.isLoading)

  const segments: Segment<T>[] = responses.map((x, index) => {
    const segmentIndex = getIndexForState(textSegmentStates[index])
    return {
      color: segmentConfigurations[segmentIndex].color,
      label: segmentConfigurations[segmentIndex].label,
      data: x.data ? x.data : null,
    }
  })

  return useMemo(() => ({ isLoading, segments }), [isLoading, segments])
}
