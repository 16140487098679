import {
  ShareStatisticsTimeSeriesBaseEntry,
  ShareStatisticsTimeSeriesResponseModel,
  TextShareStatisticsTimeSeriesBaseEntry,
  TextShareStatisticsTimeSeriesResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import {
  CartesianGrid,
  DateBucket,
  LineChartSkeleton,
  LineDescriptor,
  PrimaryLine,
  ReferenceLine,
  TimeSeriesChart,
  TimeSeriesTooltip,
  XAxis,
  YAxis,
  adjustDateForDSTOverlap,
} from '@capturi/charts'
import { TimeResolution } from '@capturi/core'
import {
  Segment,
  useFetchPhoneSegments,
  useFilterPeriodContext,
} from '@capturi/filters'
import { Box, HStack, Stack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { isWeekend } from 'date-fns'
import React from 'react'
import { ResponsiveContainer, Tooltip } from 'recharts'

import ResolutionButtons from '../../../../analytics/shared/components/ResolutionButtons'
import { useTimeResolutionContext } from '../../../../analytics/shared/contexts/ResolutionContext'
import useOnlyWeekdaysToggle from '../../../../analytics/shared/hooks/useOnlyWeekdaysToggle'
import { pctFormat } from '../../../../analytics/shared/utils'
import { useFetchTextSegments } from '../../hooks/useFetchTextSegments'
import { TargetStatistics } from '../UserBreakdown'

type TimeSeriesProps = {
  segmentAverages: Segment<TargetStatistics>[]
}

export const TimeSeries: React.FC<TimeSeriesProps> = ({ segmentAverages }) => {
  const { i18n } = useLingui()
  const { resolution, setResolution } = useTimeResolutionContext()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1

  const [plotOnlyWeekdays, OnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const onResolutionChange = (resolution: TimeResolution): void => {
    setResolution(resolution)
  }

  const { segments: phoneSegments, isLoading: isLoadingPhone } =
    useFetchPhoneSegments<ShareStatisticsTimeSeriesResponseModel>(
      () => insightsAPI.getShareStatisticsTimeSeries(resolution, tzOffset),
      (state, _, periodDef, mapperFn) => {
        return {
          baseFilter: mapperFn(state.values, periodDef),
          shareFilter: mapperFn(state.subFilterState?.values, periodDef),
        }
      },
    )

  const { segments: textSegments, isLoading: isLoadingText } =
    useFetchTextSegments<TextShareStatisticsTimeSeriesResponseModel>({
      url: 'insights/share/text/time-series',
      query: {
        resolution,
        offset: tzOffset,
      },
    })

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = {
          date,
          words: {},
          users: {},
        }
      }
      return buckets[bucketKey]
    }

    function addSegmentDataToBucket<
      T extends
        | TextShareStatisticsTimeSeriesBaseEntry
        | ShareStatisticsTimeSeriesBaseEntry,
    >(
      segments: Segment<{ series: T[] } | null>[],
      getHitPercent: (x: T) => number | null,
    ): void {
      segments.forEach((s) => {
        ;(s.data?.series ?? []).forEach((x) => {
          const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)

          // Omit weekend data?
          if (
            plotOnlyWeekdays &&
            isWeekend(date) &&
            (resolution === 'Hours' || resolution === 'Days')
          ) {
            return
          }

          const segmentKey = s.label
          const segmentColor = s.color
          const { dateTime, ...restData } = x
          const hitPercent = getHitPercent(x)
          const value = hitPercent == null ? null : hitPercent / 100

          const bucket = getOrCreateBucket(date)
          const datum = {
            value,
            segmentColor,
            ...restData,
          }

          const root = bucket
          if (Array.isArray(root[segmentKey])) {
            root[segmentKey] = [...root[segmentKey], datum]
          } else {
            root[segmentKey] = datum
          }
        })
      })
    }

    function addPhoneSegmentDataToBucket(
      segments: Segment<{
        series: ShareStatisticsTimeSeriesBaseEntry[]
      } | null>[],
    ): void {
      addSegmentDataToBucket(segments, (x) => x.conversationsSharePercent)
    }

    function addTextSegmentDataToBucket(
      segments: Segment<{
        series: TextShareStatisticsTimeSeriesBaseEntry[]
      } | null>[],
    ): void {
      addSegmentDataToBucket(segments, (x) => x.casesSharePercent)
    }

    // Add primary segment data
    addPhoneSegmentDataToBucket(phoneSegments)
    addTextSegmentDataToBucket(textSegments)

    const phoneSegmentLines = phoneSegments.reduce<LineDescriptor[]>(
      (memo, s) => {
        // Only add lines when we also have data. Charts will do weird aninmations otherwise.
        if (s.data == null) return memo
        memo.push({
          id: `primary:phone-${s.label}:${s.color}`,
          color: s.color,
          label: s.label,
          getValue: (d) => {
            return d[s.label]?.value ?? null
          },
        })
        return memo
      },
      [],
    )

    const textSegmentLines = textSegments.reduce<LineDescriptor[]>(
      (memo, s) => {
        // Only add lines when we also have data. Charts will do weird aninmations otherwise.
        if (s.data == null) return memo
        memo.push({
          id: `primary:text-${s.label}:${s.color}`,
          color: s.color,
          label: s.label,
          getValue: (d) => {
            return d[s.label]?.value ?? null
          },
        })
        return memo
      },
      [],
    )

    const segmentLines = [...phoneSegmentLines, ...textSegmentLines]

    return {
      dataset: Object.values(buckets),
      segmentLines,
    }
  }, [phoneSegments, textSegments, tzOffset, plotOnlyWeekdays, resolution])

  const primaryLines = results.segmentLines
  const referenceLines = segmentAverages.map(({ color, label, data }) => ({
    label,
    color,
    y: data ? data.targetsSharePercent / 100 : undefined,
  }))
  return (
    <Stack spacing={8}>
      <Box>
        <HStack spacing={4} mb={2}>
          <ResolutionButtons
            resolution={resolution}
            setResolution={onResolutionChange}
          />
          {OnlyWeekdaysToggle}
        </HStack>
        <Box w="100%" h="18rem">
          <LineChartSkeleton isLoaded={!(isLoadingPhone || isLoadingText)}>
            <ResponsiveContainer width="100%" height="100%" minWidth={100}>
              <TimeSeriesChart
                data={results.dataset}
                resolution={resolution}
                margin={{
                  right: 50,
                }}
              >
                <CartesianGrid />
                <Tooltip
                  content={
                    <TimeSeriesTooltip
                      resolution={resolution}
                      formatValue={(value, name, d) => {
                        const numberValue = i18n.number(value, pctFormat)
                        const bucket = d[name]
                        if ('conversations' in bucket) {
                          return t`${numberValue} (${d[name].conversationsShare} out of ${d[name].conversations} conversations)`
                        }
                        if ('cases' in bucket) {
                          return t`${numberValue} (${d[name].casesShare} out of ${d[name].cases} tickets)`
                        }
                        return numberValue
                      }}
                    />
                  }
                />
                <XAxis dataKey="date" />
                <YAxis valueType="percent" />
                {primaryLines.map((line) => (
                  <PrimaryLine key={line.id} line={line} />
                ))}
                {referenceLines.map((line) => (
                  <ReferenceLine key={line.label} line={line} />
                ))}
              </TimeSeriesChart>
            </ResponsiveContainer>
          </LineChartSkeleton>
        </Box>
      </Box>
    </Stack>
  )
}
