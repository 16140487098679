import {
  TeamShareStatisticsResponseModel,
  TextTeamShareStatisticsResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import {
  createPeriodParams,
  toFilterSearchParams,
  useFetchPhoneSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { ConversationsDrawer } from 'components/ConversationsDrawer'
import { ColumnDefinition, DataGrid } from 'components/DataGrid'
import NoDataText from 'pages/analytics/shared/components/NoDataText'
import UserBreakdownSkeleton from 'pages/analytics/shared/components/UserBreakdownSkeleton'
import { TeamSegments } from 'pages/analytics/shared/types'
import { memo, useCallback, useMemo } from 'react'

import { useCasesDrawer } from '../../../../../components/CasesDrawer'

import { Event, logEvent } from '../../../../analytics/shared/events'
import { formatValue, pctFormat } from '../../../../analytics/shared/utils'
import { useFetchTextSegments } from '../../hooks/useFetchTextSegments'
import {
  TargetTeamSegmentsData,
  convertHitsToTextTeamItemSegments,
  useTeamBreakdownStats,
} from './useTeamBreakdownStats'

export const TeamBreakdown = memo(() => {
  const { phoneSegmentStates, textSegmentStates, getIndexForState } =
    useSegmentStatesContext()

  const { segments: phoneSegments, isLoading: isLoadingPhone } =
    useFetchPhoneSegments<TeamShareStatisticsResponseModel>(
      insightsAPI.getShareStatisticsByTeam,
      (state, _, periodDef, mapperFn) => {
        return {
          baseFilter: mapperFn(state.values, periodDef),
          shareFilter: mapperFn(state.subFilterState?.values, periodDef),
        }
      },
    )

  const { segments: textSegments, isLoading: isLoadingText } =
    useFetchTextSegments<TextTeamShareStatisticsResponseModel>({
      url: 'insights/share/text/teams',
    })

  const res = convertHitsToTextTeamItemSegments(
    phoneSegmentStates,
    phoneSegments,
    textSegmentStates,
    textSegments,
    getIndexForState,
  )

  const stats = useTeamBreakdownStats(res)

  const { periodDef } = useFilterPeriodContext()
  const { states } = useSegmentStatesContext()
  const [openConversationsDrawer] = useModal(ConversationsDrawer)
  const openCasesDrawer = useCasesDrawer()

  const handleGoToPhoneTrackerUserConversations = useCallback(
    (stateIndex: number, row: TeamSegments): void => {
      const state = states[stateIndex]
      if (state.channel !== 'phone') {
        return
      }

      logEvent(Event.DataGrid_ViewTeamConversations, { dataTabName: 'hitrate' })
      openConversationsDrawer({
        url: `insights/share/team/${row.team.uid}/conversations?api-version=3.3`,
        getFilterRequestModel: () => {
          const { values, subFilterState } = state
          return {
            baseFilter: toFilterSearchParams(
              {
                ...values,
                teamUids: [row.team.uid],
              },
              periodDef,
            ),
            shareFilter: toFilterSearchParams(
              subFilterState?.values,
              periodDef,
            ),
          }
        },
      })
    },
    [openConversationsDrawer, states, periodDef],
  )

  const handleGoToEmailTrackerUserConversations = useCallback(
    (stateIndex: number, row: TeamSegments): void => {
      const state = states[stateIndex]
      if (state.channel !== 'email') {
        return
      }

      logEvent(Event.DataGrid_ViewTeamConversations, { dataTabName: 'hitrate' })

      openCasesDrawer({
        url: `insights/share/text/team/${row.team.uid}/cases?api-version=3.3`,
        getFilterRequestModel: () => {
          const { values, subFilterState } = state
          const periodParams = createPeriodParams(periodDef.create(new Date()))
          return {
            baseFilter: {
              ...values,
              ...periodParams,
            },
            shareFilter: {
              ...subFilterState?.values,
              ...periodParams,
            },
          }
        },
      })
    },
    [openCasesDrawer, states, periodDef],
  )

  const columns = useMemo(() => {
    const columns: Array<ColumnDefinition<TargetTeamSegmentsData>> = [
      {
        type: 'team',
        getValue: (d) => d.team.uid,
      },
      {
        type: 'value',
        getSegmentValue: (s) => s.data?.targetsSharePercent,
        formatValue: (value) => formatValue(value, pctFormat),
        alignRight: true,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.targetsSharePercent,
        referenceLines: res.reduce<
          { value: number; color: string; label: string }[]
        >((memo, s) => {
          if (s.data != null) {
            const value = s.data.targetsSharePercent / 100
            memo.push({
              label: i18n.number(value, pctFormat),
              color: s.color,
              value,
            })
          }
          return memo
        }, []),
      },
      {
        type: 'dataBasis',
        getSegmentValue: (s) => {
          const { targetsShare = 0, targets = 0 } = s.data ?? {}
          return {
            value: targetsShare,
            total: targets,
          }
        },
      },
      {
        type: 'button',
        render: (row, rowSegmentIndex) => {
          const data = row.segments[rowSegmentIndex].data
          if (!data) return <Box />
          const stateIndex = data.stateIndex
          const isEmailChannel = states[stateIndex].channel === 'email'
          return (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() =>
                isEmailChannel
                  ? handleGoToEmailTrackerUserConversations(stateIndex, row)
                  : handleGoToPhoneTrackerUserConversations(stateIndex, row)
              }
            />
          )
        },
      },
    ]
    return columns
  }, [
    handleGoToPhoneTrackerUserConversations,
    handleGoToEmailTrackerUserConversations,
    res,
    states,
  ])

  return (
    <UserBreakdownSkeleton isLoaded={!(isLoadingPhone || isLoadingText)}>
      <DataGrid data={stats} columns={columns}>
        <NoDataText show={!isLoadingPhone && stats.length === 0} mt={8} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
})
